import React, { PropsWithChildren, useState } from 'react';
import { TableSpecs } from '../../pages/Credentials/Credentials';
import {
  CredentialsContext,
  CredentialsContextTypes,
  INITIAL_TABLE_SPECS,
} from './CredentialsContext';
import { CredentialType } from '../../domain/credentialTypes';
import { getCredentialTypes } from '../../services/vidcredentials';
import useCredentials from '../../pages/Credentials/useCredentials';

interface CredentialProviderProps<CredentialsListType, CredentialsListFilters> {
  context: React.Context<
    CredentialsContext<CredentialsListType, CredentialsListFilters>
  >;
  initialFilters: CredentialsListFilters;
  credentialsContextType?: CredentialsContextTypes;
}

function CredentialsProviderCreator<
  CredentialsListType,
  CredentialsListFilters,
>({
  context,
  children,
  initialFilters,
  credentialsContextType,
}: PropsWithChildren<
  CredentialProviderProps<CredentialsListType, CredentialsListFilters>
>) {
  const [filters, setFilters] =
    useState<CredentialsListFilters>(initialFilters);
  const [credentials, setCredentials] = useState<CredentialsListType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableSpecs, setTableSpecs] = useState<TableSpecs>(INITIAL_TABLE_SPECS);
  const [credentialTypes, setCredentialTypes] = useState<CredentialType[]>([]);
  const [advancedFilterModal, setAdvancedFilterModal] = useState(false);
  const loadSelectCredentialTypes = async () => {
    const response = await getCredentialTypes();
    if ('error' in response) {
      return;
    }
    const parsedData = response.map(({ id, type, template }) => ({
      id,
      template,
      name: type,
    }));

    setCredentialTypes(parsedData);
  };

  const { loadCredentials } = useCredentials({
    setCredentials,
    setIsLoading,
    setTableSpecs,
    loadSelectCredentialTypes,
    credentialsContextType,
  });

  const { Provider } = context;

  return (
    <Provider
      value={{
        isLoading,
        setIsLoading,
        filters,
        setFilters,
        loadCredentials,
        credentials,
        setCredentials,
        tableSpecs,
        setTableSpecs,
        credentialTypes,
        loadSelectCredentialTypes,
        advancedFilterModal,
        setAdvancedFilterModal,
      }}
    >
      {children}
    </Provider>
  );
}

export default CredentialsProviderCreator;
