import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Login } from './pages/Login/Login';
import { EmailLogin } from './pages/EmailLogin/EmailLogin';
import { Callback } from './pages/Callback/Callback';
import { LoginCallback } from './pages/LoginCallback/LoginCallback';
import { Home } from './pages/Home/Home';

import { IssueCredentialForm } from './components/IssueCredentialForm/IssueCredentialForm';
import { Credentials } from './pages/Credentials/Credentials';
import { UserCredentials } from './pages/UserCredentials/UserCredentials';
import { AuditTrails } from './pages/AuditTrails/AuditTrails';

import { UserCredentialsContextProvider } from './contexts/CredentialsProviders/Providers/UserCredentialsContext';
import { AuthContext } from './contexts/AuthContext';
import { CredentialRequests } from './pages/CredentialRequests/CredentialRequests';
import { CredentialRequestsContextProvider } from './contexts/CredentialsProviders/Providers/CredentialRequestsContext';
import { createTheme, ThemeProvider } from '@mui/material';
import { AllCredentialsContextProvider } from './contexts/CredentialsProviders/Providers/AllCredentialsContext';
import { AuditTrailsContextProvider } from './contexts/CredentialsProviders/Providers/AuditTrailsContext';

function App() {
  const { accessToken } = useContext(AuthContext);
  const defaultMaterialTheme = createTheme();

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const alertUser = (e: {
    preventDefault: () => void;
    returnValue: string;
  }) => {
    const now = new Date().getTime();
    const lastTime = Number(localStorage.getItem('last_refresh_token_request'));
    if (!lastTime || now - lastTime > 1000) {
      localStorage.setItem(
        'last_refresh_token_request',
        new Date().getTime().toString(),
      );
      return;
    }
    e.preventDefault();
    e.returnValue = '';
  };

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <AllCredentialsContextProvider>
                <Home />
              </AllCredentialsContextProvider>
            }
          >
            <Route path="/credentials" element={<Credentials />} />
            <Route path="/new-credential" element={<IssueCredentialForm />} />
            <Route
              path="/user-credentials"
              element={
                <UserCredentialsContextProvider>
                  <UserCredentials />
                </UserCredentialsContextProvider>
              }
            />
            <Route
              path="/credential-requests"
              element={
                <CredentialRequestsContextProvider>
                  <CredentialRequests />
                </CredentialRequestsContextProvider>
              }
            />
            <Route
              path="/audit-trails"
              element={
                <AuditTrailsContextProvider>
                  <AuditTrails />
                </AuditTrailsContextProvider>
              }
            />
            <Route
              path="/"
              element={
                <Navigate
                  to={accessToken ? '/credentials' : '/login'}
                  replace
                />
              }
            />
          </Route>
          <Route
            path="/email-login/:credentialRequestId"
            element={<EmailLogin />}
          />
          <Route path="/callback" element={<Callback />} />
          <Route path="/login-callback" element={<LoginCallback />} />
          <Route path="*" element={<Navigate to="/credentials" replace />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
