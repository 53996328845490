import { SelectInputOption } from '../FormBuilder';

export const statusOptions: SelectInputOption[] = [
  { translationKey: 'table.status', value: '' },
  { translationKey: 'status.CREATED', value: 'CREATED' },
  { translationKey: 'status.ISSUED', value: 'ISSUED' },
  { translationKey: 'status.REVOKED', value: 'REVOKED' },
  {
    translationKey: 'status.REVOCATION_REQUESTED',
    value: 'REVOCATION_REQUESTED',
  },
  { translationKey: 'status.VERIFIED', value: 'VERIFIED' },
  { translationKey: 'status.UNVERIFIED', value: 'UNVERIFIED' },
  { translationKey: 'status.ISSUE_REQUESTED', value: 'ISSUE_REQUESTED' },
  { translationKey: 'status.REVOKED_FAILED', value: 'REVOKED_FAILED' },
];

export const credentialRequestsStatusOptions: SelectInputOption[] = [
  { translationKey: 'table.status', value: '' },
  { translationKey: 'requestStatus.REQUESTED', value: 'REQUESTED' },
  { translationKey: 'requestStatus.APPROVED', value: 'APPROVED' },
];
export const placeholders: Record<string, string> = {
  did: 'DID',
  email: 'Email',
  searchableText: 'filters.any',
};

export const ACTION_NAME_OPTIONS: SelectInputOption[] = [
  { translationKey: 'filters.actionName', value: '' },
  { translationKey: 'auditTrailsActions.UserLogin', value: 'UserLogin' },
  {
    translationKey: 'auditTrailsActions.CredentialOrderCreated',
    value: 'CredentialOrderCreated',
  },
  {
    translationKey: 'auditTrailsActions.ResendCredentialOrderEmail',
    value: 'ResendCredentialOrderEmail',
  },
  {
    translationKey: 'auditTrailsActions.CredentialHolderAuthenticated',
    value: 'CredentialHolderAuthenticated',
  },
  {
    translationKey: 'auditTrailsActions.CredentialIssued',
    value: 'CredentialIssued',
  },
  {
    translationKey: 'auditTrailsActions.CredentialRevocationRequested',
    value: 'CredentialRevocationRequested',
  },
  {
    translationKey: 'auditTrailsActions.CredentialRevoked',
    value: 'CredentialRevoked',
  },
  {
    translationKey: 'auditTrailsActions.CredentialRequestCreated',
    value: 'CredentialRequestCreated',
  },
  {
    translationKey: 'auditTrailsActions.CredentialRequestConfirmed',
    value: 'CredentialRequestConfirmed',
  },
  {
    translationKey: 'auditTrailsActions.CredentialRequestDocumentSigned',
    value: 'CredentialRequestDocumentSigned',
  },
  {
    translationKey: 'auditTrailsActions.PresentationCreated',
    value: 'PresentationCreated',
  },
];
